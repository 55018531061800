import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const AboutPage = () => (
    <div className="body">
        <Layout>
            <SEO title="About" />
            <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                <section className="section">
                    <div className="page-heading-parent">
                        <h1>About <em>The Pub</em></h1>
                        <div className="line grey"></div>
                    </div>
                    <div className="rich-text-block w-richtext">
                        <h2>Mission</h2>
                        <p><em>The Pub</em> creates a forum for academic and artistic discussion at Wheaton by producing diverse and thoughtful content which engages the intersection of intellectual life and Christian faith. The Pub serves as an opportunity for Wheaton students to participate in an editorial and production process that seeks to provide a window to the broader world of academia and challenge students to put their voice into the mix. The Pub is a place where discussions of academia and art can happen student-to-student, and the joy which comes with learning can be shared side by side.</p>
                        <h2>Affiliation<br /></h2>
                        <p><em>The Pub</em> is a publication independently run by Wheaton College students. It is not an official publication of the college. The staff of <em>The Pub</em> are not paid, because the project is solely funded by advertising, fundraising, and a small club allocations budget. However, staff of <em>The Pub</em> have been known to receive jobs, internships, and admission to graduate programs after graduation in graphic design, publishing, budget management, and academic editing due to their involvement in <em>The Pub.</em> <br /></p>
                    </div>
                    <h2>Interested in Joining <em>The Pub?</em></h2>
                    <p><em>The Pub</em> is not currently seeking new members. Please follow @wheatonpub on Instagram to stay up to date on our recruiting periods and any open opportunities.
                    </p>
                </section>
            </div>
        </Layout>
    </div>
)
export default AboutPage
